import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash, FaTimes } from 'react-icons/fa';
import * as XLSX from 'xlsx'; // For Excel download
import axios from 'axios';

function CustomerPage() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState({
    customer_name: '',
    phonenumber: '',
    address: '',
    email: '',
    employee_id: '',
    employee_name: '',
    shop_name: '',
    shop_number: '',
    area_name: '',
    area_id: '',
    image: null,
    description: ''
  });
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  // const [employees, setEmployees] = useState([]);
  const [areas, setAreas] = useState([]);


  const token = localStorage.getItem("accessToken");

  // get all customers

  const fetchCustomers = async () => {
    try {
      const response = await axios.get('https://server.ramakumaritrades.co.in/web/customer/get-all-customer', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch brands. Status: ' + response.status);
      }

      console.log("data", response.data);
      const data = response.data;
      setCustomers(data.data || []);
      localStorage.setItem('customer_id', JSON.stringify(data.data.map(customers => customers.customer_id)));
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };
  useEffect(() => {
    fetchCustomers();
  }, [token]);



  // Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };



  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCsvChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = (customer) => {
    setSelectedCustomer(customer);
    setFormData(customer || {
      customer_name: '',
      phonenumber: '',
      address: '',
      email: '',
      employee_id: '',
      employee_name: '',
      shop_name: '',
      shop_number: '',
      area_name: '',
      area_id: '',
      image: null,
      description: ''
    });
    setShowEditModal(!showEditModal);
  };

  //  add customer

  const handleAddCustomer = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));

      const response = await fetch('https://server.ramakumaritrades.co.in/web/customer/create-customer', {
        method: 'POST',
        body: formDataToSend,
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) throw new Error('Failed to add customer.');

      const data = await response.json();
      setCustomers([...customers, data.data]);
      toggleAddModal();
    } catch (error) {
      // console.error('Error adding customer:', error);
   
    }
  };

  // csv file upload

  const handleUploadCsv = async () => {
    if (!csvFile) {
      console.error("No CSV file selected");
      return;
    }

    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error("Authorization token is missing.");
      return;
    }

    try {
      // Prepare FormData with the file
      const formData = new FormData();
      formData.append('csv_file', csvFile); // Appending CSV file with the correct key "csv_file"

      // Send the POST request to bulk upload the products
      const response = await fetch('https://server.ramakumaritrades.co.in/web/customer/bulk-upload-customer', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
          // No need to set Content-Type; it will be set automatically with FormData
        }
      });

      // Check if the upload was successful
      if (!response.ok) {
        throw new Error(`Failed to upload CSV file: ${response.statusText}`);
      }

      const data = await response.json(); // Server response
      console.log("Server response:", data);


      // Assuming fetchProductData() reloads product data in the UI
      alert("CSV file uploaded and products added successfully.");
      fetchCustomers();
    } catch (error) {
      console.error('Error during CSV upload:', error.message);
      alert('An error occurred during the upload process.');
    }
  };

  // edit customer

  const handleEditCustomer = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();

      // Append fields if they have new values
      if (formData.customer_name) formDataToSend.append('customer_name', formData.customer_name);
      if (formData.phonenumber) formDataToSend.append('phonenumber', formData.phonenumber);
      if (formData.address) formDataToSend.append('address', formData.address);
      if (formData.email) formDataToSend.append('email', formData.email);
      if (formData.employee_id) formDataToSend.append('employee_id', formData.employee_id);
      if (formData.employee_name) formDataToSend.append('employee_name', formData.employee_name);
      if (formData.shop_name) formDataToSend.append('shop_name', formData.shop_name);
      if (formData.shop_number) formDataToSend.append('shop_number', formData.shop_number);
      if (formData.area_name) formDataToSend.append('area_name', formData.area_name);
      if (formData.area_id) formDataToSend.append('area_id', formData.area_id);
      if (formData.description) formDataToSend.append('description', formData.description);
      if (formData.image) formDataToSend.append('image', formData.image);

      // Append customer_id as a required identifier
      formDataToSend.append('customer_id', selectedCustomer.customer_id);

      const response = await fetch("https://server.ramakumaritrades.co.in/web/customer/update-customer", {
        method: 'PUT',
        body: formDataToSend,
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to update customer. Status: ' + response.status);
      }

      const data = await response.json();
      if (data.success) {
        setCustomers(customers.map(customer =>
          customer.customer_id === selectedCustomer.customer_id
            ? {
              ...customer,
              customer_name: formData.customer_name || customer.customer_name,
              phonenumber: formData.phonenumber || customer.phonenumber,
              address: formData.address || customer.address,
              email: formData.email || customer.email,
              employee_id: formData.employee_id || customer.employee_id,
              employee_name: formData.employee_name || customer.employee_name,
              shop_name: formData.shop_name || customer.shop_name,
              shop_number: formData.shop_number || customer.shop_number,
              area_name: formData.area_name || customer.area_name,
              area_id: formData.area_id || customer.area_id,
              description: formData.description || customer.description,
              image: formData.image || customer.image,
              customer_id: selectedCustomer.customer_id
            }
            : customer
        ));

        toggleEditModal(null); // Close the modal
        fetchCustomers();
      } else {
        console.error('Failed to update customer:', data.message);
      }
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  // delete customer

  const handleDeleteCustomer = async (customer_id) => {
    console.log(`Deleting customer with ID: ${customer_id}`);

    if (!customer_id) {
      console.error('Invalid customer_id:', customer_id);
      return;
    }

    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        const response = await fetch("https://server.ramakumaritrades.co.in/web/customer/delete-customer-by-id", {
          method: 'DELETE',
          body: JSON.stringify({
            customer_id: customer_id,
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Authorization header for authentication
          },
        });

        // Check for successful response
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Update the state to reflect the deleted customer
            setCustomers(prevCustomers => prevCustomers.filter(customer => customer.customer_id !== customer_id));
          } else {
            console.error('Failed to delete customer:', data.message);
          }
        } else if (response.status === 404) {
          console.error('Customer not found. Please check the customer_id or the backend route.');
        } else {
          console.error('Failed to delete customer. Status:', response.status);
        }
      } catch (error) {
        console.error('Error deleting customer:', error);
      }
    }
  };

  // downloade excel customer

  const handleDownloadExcel = () => {
    const dataToExport = customers.map(customer => ({
      'Customer ID': customer.customer_id,
      'Customer Name': customer.customer_name,
      'Phone Number': customer.phonenumber,
      'Address': customer.address,
      'Email': customer.email,
      'Employee Id': customer.employee_id,
      'employee Name': customer.employee_name,
      'Shop Name': customer.shop_name,
      'Shop Number': customer.shop_number,
      'Area Name': customer.area_name,
      'Area ID': customer.area_id,
      'Description': customer.description
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
    XLSX.writeFile(workbook, 'customers.xlsx');
  };

  const filteredCustomers = customers.filter(customer =>
    customer.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  // get all areas

  const fetchAreas = async () => {
    try {
      const response = await axios.get('https://server.ramakumaritrades.co.in/web/area/get-all-area', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch areas. Status: ' + response.status);
      }

      console.log("data", response.data);
      const data = response.data;
      setAreas(data.data || []); // Update state with area data
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
  };

  useEffect(() => {
    fetchAreas();
  }, [token]);

  // Handle area selection change
  const handleAreaChange = (event) => {
    const selectedAreaId = event.target.value;
    const selectedArea = areas.find(area => area.area_id === selectedAreaId);

    setFormData({
      ...formData,
      area_name: selectedArea ? selectedArea.area_name : '',
      area_id: selectedAreaId
    });
  };

  return (
    <div className="flex flex-col   h-[calc(100vh-6rem)] overflow-hidden">
      {/* Controls Section */}
      <div className="flex flex-wrap mb-2 justify-between">
        <div className="flex flex-wrap gap-4 w-full md:w-auto">
          <input
            type="file"
            accept=".csv"
            onChange={handleCsvChange}
            className="border border-gray-300 rounded px-2 py-2 w-full md:w-64"
          />
          <button
            onClick={handleUploadCsv}
            className="bg-navy text-white px-4 py-2 rounded w-full md:w-auto"
          >
            Upload CSV
          </button>
        </div>
        <div className="flex flex-wrap gap-4 mt-2 md:mt-0">
          <button
            onClick={toggleAddModal}
            className="bg-navy text-white px-4 py-2 mx-2 rounded w-full md:w-auto"
          >
            Add Customer
          </button>
          <button
            onClick={handleDownloadExcel}
            className="bg-navy text-white px-4 py-2 mx-2 rounded w-full md:w-auto"
          >
            Download Excel
          </button>
        </div>
      </div>

      {/* Search and Download Section */}
      <div className="flex flex-wrap items-center mb-2 border w-full md:w-1/2 border-gray-300 rounded">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by customer name"
          className="px-4 py-2 w-full"
        />
      </div>

      <div className=" w-[calc(100vw-14rem)]  overflow-x-auto h-[calc(100vh-4rem)]  overflow-y-auto ">
        <table className="table-auto min-w-[2000px]">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="border-b px-2 py-2 ">S.NO</th>
              <th className="border-b px-2 py-2 ">Customer Id</th>
              <th className="border-b px-2 py-2 ">Name</th>
              <th className="border-b px-2 py-2 ">Phone Number</th>
              <th className="border-b px-2 py-2 ">Address</th>
              <th className="border-b px-2 py-2 ">Email</th>
              {/* <th className="border-b px-2 py-2 ">Employee Id</th>
              <th className="border-b px-2 py-2 ">Employee Name</th> */}
              <th className="border-b px-2 py-2 ">Shop Name</th>
              <th className="border-b px-2 py-2 ">Shop Number</th>
              <th className="border-b px-2 py-2 ">Area Name</th>
              <th className="border-b px-2 py-2 ">Area ID</th>
              <th className="border-b px-2 py-2 ">Description</th>
              <th className="border-b px-2 py-2 ">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer, index) => (
              <tr key={customer.customer_id}>
                <td className="border-b px-2  ">{index + 1}</td>
                <td className="border-b px-2  ">{customer.customer_id}</td>
                {/* <td className="border-b px-2  ">
                  <img
                    src={`https://server.ramakumaritrades.co.in/storage/userdp/${customer.image}`}
                    alt={customer.customer_name}
                    className="h-10 w-10 object-cover"
                  />
                </td> */}
                <td className="border-b px-2  ">{customer.customer_name}</td>
                <td className="border-b px-2  ">{customer.phonenumber}</td>
                <td className="border-b px-2  ">{customer.address}</td>
                <td className="border-b px-2  ">{customer.email}</td>
                {/* <td className="border-b px-2  ">{customer.employee_id}</td>
                <td className="border-b px-2  ">{customer.employee_name}</td> */}
                <td className="border-b px-2  ">{customer.shop_name}</td>
                <td className="border-b px-2  ">{customer.shop_number}</td>
                <td className="border-b px-2  ">{customer.area_name}</td>
                <td className="border-b px-2  ">{customer.area_id}</td>
                <td className="border-b px-2  ">{customer.description}</td>
                <td className="border-b border-gray-300 px-4 py-2 ">
                  <button
                    onClick={() => toggleEditModal(customer)}
                    className="text-navy hover:text-blue-800 mx-2"
                  >
                    <FaEdit size={16} /> {/* Edit icon */}
                  </button>
                  <button
                    onClick={() => handleDeleteCustomer(customer.customer_id)}
                    className="text-red-600 hover:text-red-800 mx-2"
                  >
                    <FaTrash size={16} /> {/* Delete icon */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add Brand Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-3xl h-full max-h-[80vh] overflow-y-auto">
            <div className='flex justify-between'>
              <h2 className="text-xl mb-4">Add New Customer</h2>
              <button
                type="button"
                onClick={toggleAddModal}
                className="bg-red-500 text-white px-4 py-2 rounded ml-4"
              >
                <FaTimes size={16} />
              </button>
            </div>
            <form onSubmit={handleAddCustomer}>
              <div className="grid grid-cols-2 gap-4">

                <div>
                  <label>Customer Name:</label>
                  <input
                    type="text"
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div>
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    name="phonenumber"
                    value={formData.phonenumber}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div>
                  <label>Email:</label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Shop Name:</label>
                  <input
                    type="text"
                    name="shop_name"
                    value={formData.shop_name}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div>
                  <label>Shop Number:</label>
                  <input
                    type="text"
                    name="shop_number"
                    value={formData.shop_number}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                {/* Area ID */}
                <div>
                  <label>Area Name:</label>
                  <select
                    name="area_name"
                    // value={formData.area_id}
                    onChange={handleAreaChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  >
                    <option value="" disabled>Select an area</option>
                    {areas.map((area) => (
                      <option key={area.area_id} value={area.area_id}>
                        {area.area_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Area ID:</label>
                  <input
                    type="text"
                    name="area_id"
                    value={formData.area_id}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                    readOnly // Make this field read-only if area_id is selected via dropdown
                  />
                </div>
             

              </div>

              <div className="mt-4">
                <label>Description:</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded px-2 py-1 w-full"
                />
              </div>

              <div className="mt-4 flex justify-end gap-4">
                <button
                  type="submit"
                  className="bg-navy text-white px-4 py-2 rounded"
                >
                  ADD
                </button>

              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Customer Modal */}
      {showEditModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-3xl h-full max-h-[80vh] overflow-y-auto">
            <div className='flex justify-between'>
              <h2 className="text-xl mb-4">Edit Customer</h2>
              <button
                type="button"
                onClick={() => toggleEditModal(null)}
                className="bg-red-500 text-white px-4 py-2 rounded ml-4"
              >
                <FaTimes size={16} />

              </button>

            </div>
            <form onSubmit={handleEditCustomer}>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-1">
                  <label>Customer Name:</label>
                  <input
                    type="text"
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                {/* <div className="col-span-1">
                  <label>Customer Image:</label>
                  <input
                    type="file"
                    name="image"
                    onChange={handleFileChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div> */}
                <div className="col-span-1">
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    name="phonenumber"
                    value={formData.phonenumber}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div className="col-span-1">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div>
                  <label>Employee Name:</label>
                  <input
                    type="text"
                    name="employee_name"
                    value={formData.employee_name}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Employee Id:</label>
                  <input
                    type="text"
                    name="employee_id"
                    value={formData.employee_id}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>


                <div className="col-span-1">
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div className="col-span-1">
                  <label>Shop Name:</label>
                  <input
                    type="text"
                    name="shop_name"
                    value={formData.shop_name}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div className="col-span-1">
                  <label>Shop Number:</label>
                  <input
                    type="text"
                    name="shop_number"
                    value={formData.shop_number}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div className="col-span-1">
                  <label>Area Name:</label>
                  <input
                    type="text"
                    name="area_name"
                    value={formData.area_name}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div className="col-span-2">
                  <label>Description:</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  ></textarea>
                </div>
              </div>

              <div className="mt-4 flex justify-end">
                <button
                  type="submit"
                  className="bg-navy text-white px-4 py-2 rounded"
                >
                  Save
                </button>

              </div>
            </form>
          </div>
        </div>
      )}

    </div>
  );
}

export default CustomerPage;
